<template>
  <div>
    <b-row>
      <b-col cols="6">
        <h2 class="mb-4 font-weight-bold">
          <small class="small"><i class="fe fe-tag"></i></small> Clients
        </h2>
      </b-col>
      <b-col v-if="$route.path !== '/clients'" class="text-right" cols="6">
        <router-link :to="{ path: '/clients' }">
          <i class="fe fe-arrow-left"></i> Back
        </router-link>
      </b-col>
    </b-row>
    <router-view />
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['settings']),
    ...mapState('portal', ['data']),
  },
}
</script>
